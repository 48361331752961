<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="客服管理"
        >
          <!-- <template slot="extra">
                      <a-button
                        class="editable-add-btn"
                        @click="handleAdd"
                        size="small"
                      >
                        新增
                      </a-button>
                    </template> -->
        </a-page-header>
      </div>

      <a-form
        :form="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit="handleSubmit"
        style="margin-top: 24px"
      >
        <a-form-item label="客服图片：" extra="最多支持1张">
          <a-input
            v-decorator="[
              'customer_service_url',
              {
                rules: [{ required: true, message: '请选择客服图片!' }],
              },
            ]"
            type="hidden"
          ></a-input>
          <a-upload
            name="file"
            list-type="picture"
            class="upload-list-inline"
            :fileList="fileList"
            :customRequest="changeImg"
            @change="getchangeImg"
            :remove="removeImg"
            :beforeUpload="handleBeforeUpload"
          >
            <a-button :disabled="!uploadBtn">
              <a-icon type="upload" /> 上传图片
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item label="客服描述：">
          <a-input
            v-decorator="[
              'customer_service_describe',
              {
                rules: [{ required: true, message: '请输入客服描述!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="活动客服：">
          <a-input
            v-decorator="[
              'active_customer_service',
              {
                rules: [{ required: true, message: '请输入活动客服!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" html-type="submit"> 保存 </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      uploadBtn: true,
      fileList: [],
      canUpload:''
    };
  },
  mounted() {
    this.getSearchList();
  },
  methods: {
    //获取客服信息
    getSearchList() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios.get("/admin/customerService/one").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          that.customer_service_id = res.data.data.customer_service_id
            that.fileList = [
              {
                uid: "-1",
                name: "",
                status: "done",
                url: res.data.data.customer_service_url,
                thumbUrl: res.data.data.customer_service_url,
              },
            ];
          if (res.data.data.customer_service_url) {
            that.uploadBtn = false;
          } else {
            that.uploadBtn = true;
          }
          that.form.setFieldsValue({
            customer_service_url: res.data.data.customer_service_url,
            customer_service_describe: res.data.data.customer_service_describe,
            active_customer_service:res.data.data.active_customer_service,

          });
        }
      });
    },
    //上传图片
    changeImg(info) {
      var that = this;
      const formData = new FormData();
      formData.append("file", info.file);
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios.post("/admin/upload/image", formData).then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var img = res.data.data.path_url;
          that.form.setFieldsValue({
            customer_service_url: img,
          });
          that.fileList = [
            {
              uid: info.file.uid,
              name: info.file.name,
              status: "done",
              url: "" + img,
              thumbUrl: "" + img,
            },
          ];
        }
      });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    //上传前校验
    handleBeforeUpload(file){
      if(file.type.indexOf("image")<0){
      this.$message.error("仅支持上传图片")
      this.canUpload=false
      return false;
      }else{
      this.canUpload=true
        return true
      }
    },
    getchangeImg(info) {
      if(!this.canUpload){
        info.fileList=[]
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.form.setFieldsValue({
            customer_service_url: "",
          });
        }
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((error, values) => {
        if (!error) {
          values.customer_service_id = this.customer_service_id;
          var data = values;
          that.axios.post("/admin/customerService/update", data).then((res) => {
            if (that.$code(res)) {
              that.$message.success("保存成功");
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
table .screenshot {
  width: 60px;
  height: auto;
  border-radius: 0;
}
.ant-table-wrapper {
  height: auto;
}
.ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-body div {
  text-align: center;
  margin: 0 20px;
}
.ant-modal-body img {
  width: 150px;
}
.ant-modal-body div {
  width: 100%;
  text-align: left;
  margin: 0;
}
.section.ant-layout .container .ant-table-wrapper {
  overflow: scroll;
}
.section.ant-layout .container .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
}
</style>