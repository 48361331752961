var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{ref:"box"},[_c('div',{ref:"header"},[_c('a-page-header',{staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)"},attrs:{"title":"客服管理"}})],1),_c('a-form',{staticStyle:{"margin-top":"24px"},attrs:{"form":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"客服图片：","extra":"最多支持1张"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'customer_service_url',
            {
              rules: [{ required: true, message: '请选择客服图片!' }],
            } ]),expression:"[\n            'customer_service_url',\n            {\n              rules: [{ required: true, message: '请选择客服图片!' }],\n            },\n          ]"}],attrs:{"type":"hidden"}}),_c('a-upload',{staticClass:"upload-list-inline",attrs:{"name":"file","list-type":"picture","fileList":_vm.fileList,"customRequest":_vm.changeImg,"remove":_vm.removeImg,"beforeUpload":_vm.handleBeforeUpload},on:{"change":_vm.getchangeImg}},[_c('a-button',{attrs:{"disabled":!_vm.uploadBtn}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传图片 ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"客服描述："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'customer_service_describe',
            {
              rules: [{ required: true, message: '请输入客服描述!' }],
            } ]),expression:"[\n            'customer_service_describe',\n            {\n              rules: [{ required: true, message: '请输入客服描述!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"活动客服："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'active_customer_service',
            {
              rules: [{ required: true, message: '请输入活动客服!' }],
            } ]),expression:"[\n            'active_customer_service',\n            {\n              rules: [{ required: true, message: '请输入活动客服!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }